import { EXPERIMENTS, fetchExperimentVariable, initVwoClient } from '@/assets/vwo'

export async function setupCdpExperiment(isEventDetailPage, store, config, cookies) {
  if (isEventDetailPage && process.server) {
    try {
      const { vwoAccountId, vwoSdkKey, environment } = config

      const vwoClient = await initVwoClient(vwoAccountId, vwoSdkKey, environment)
      const userId = cookies.get('_vwo_uuid_v2')

      if (!vwoClient || !userId) {
        store.commit('experiments/setExperiment', [EXPERIMENTS.cv07, 'EXCLUDED'])
        return
      }

      const feature = await vwoClient.getFlag(EXPERIMENTS.cv07, { id: userId })

      if (feature.isEnabled()) {
        store.commit('experiments/setExperiment', [EXPERIMENTS.cv07, feature.getVariable('isVariant') ? 'V2' : 'C'])
      } else {
        store.commit('experiments/setExperiment', [EXPERIMENTS.cv07, 'EXCLUDED'])
      }
    } catch (e) {
      console.error(e)
      return
    }
  }

  if (isEventDetailPage && process.client) {
    const experiments = store.getters['experiments/experiments']

    if (experiments[EXPERIMENTS.cv07] === null) {
      const variant = await fetchExperimentVariable(EXPERIMENTS.cv07)

      if (typeof variant === 'boolean') {
        store.commit('experiments/setExperiment', [EXPERIMENTS.cv07, variant ? 'V2' : 'C'])
      } else {
        store.commit('experiments/setExperiment', [EXPERIMENTS.cv07, 'EXCLUDED'])
      }
    }
  }
}
