import { init } from 'vwo-fme-node-sdk'

export const EXPERIMENTS = {
  cv07: 'cv07',
}

export async function initVwoClient(vwoAccountId, vwoSdkKey, environment) {
  if (!vwoAccountId || !vwoSdkKey) {
    console.info('VWO account ID or SDK key is missing')
    return
  }

  return await init({
    accountId: vwoAccountId,
    sdkKey: vwoSdkKey,
    ...(environment === 'dev' && {
      logger: {
        level: 'INFO',
      },
    }),
  })
}

export async function fetchExperimentVariable(experimentId) {
  try {
    const res = await fetch(`/get-experiment-variable/?experimentId=${experimentId}`)

    if (!res.ok) {
      throw new Error('Failed to fetch experiment data')
    }

    const data = await res.json()
    return data[experimentId]
  } catch (error) {
    console.error(`Error fetching experiment data for ${experimentId}:`, error)
    return null
  }
}
